import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { MomentService, Vehicle } from '@fc-core';
import moment from 'moment';
import { Moment } from 'moment';
import {
  FullSchedule,
  ScheduleInterface,
} from 'src/app/core/models/operator-schedule.model';

@Component({
  selector: 'fc-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CalendarComponent implements OnInit {
  @Input() itemType: 'vehicle' | 'driver' = 'vehicle';
  @Input() itemTemplate: TemplateRef<any>;
  @Input() eventList: FullSchedule[];
  @Input() loading = false;
  @Input() dayClickFunction: Function;
  @Input() openEditFunction: Function;
  @Input() vehicleList: Vehicle[];
  @Output() eventMoved: EventEmitter<ScheduleInterface> = new EventEmitter();
  @Output() editEvent: EventEmitter<FullSchedule> = new EventEmitter();
  @Output() deleteEvent: EventEmitter<FullSchedule> = new EventEmitter();
  @Output() currentCalendarMonthEvent: EventEmitter<moment.Moment> =
    new EventEmitter();
  search = '';
  currentCalendarMonth: Moment;

  constructor(private moment: MomentService) {}

  ngOnInit(): void {
    this.currentCalendarMonth = this.moment.moment();
  }
  setCurrentMonth(event: moment.Moment) {
    this.currentCalendarMonth = event;
    this.currentCalendarMonthEvent.emit(this.currentCalendarMonth);
  }

  previousDate() {
    this.currentCalendarMonth = this.currentCalendarMonth
      .clone()
      .subtract(1, 'months')
      .add(1, 'day');
    this.currentCalendarMonthEvent.emit(this.currentCalendarMonth);
  }

  nextDate() {
    this.currentCalendarMonth = this.currentCalendarMonth
      .clone()
      .add(1, 'months')
      .add(1, 'day');
    this.currentCalendarMonthEvent.emit(this.currentCalendarMonth);
  }

  moveEvent(event) {
    this.eventMoved.emit(event);
  }
}
