import { MomentService } from '@fc-core';
import { Injectable } from '@angular/core';
import moment from 'moment/moment';
import { CalendarDay } from '@fc-shared/ui/calendar/types';
import { range } from 'lodash';
import {
  FullSchedule,
  ScheduleInterface,
} from '@fc-core/models/operator-schedule.model';

@Injectable()
export class DateContentService {
  constructor(private momentService: MomentService) {}

  getCalendarDays(selectedMonth: moment.Moment): CalendarDay[] {
    if (selectedMonth === null || selectedMonth === undefined) return [];

    const startCalendar = this.getFirstDayOfGrid(selectedMonth).date();
    const endCalendar =
      startCalendar +
      this.getLastDayOfGrid(selectedMonth).diff(
        this.getFirstDayOfGrid(selectedMonth),
        'days',
      );

    return range(startCalendar, endCalendar).map((date) => {
      const newDate = this.momentService
        .moment(this.getFirstDayOfGrid(selectedMonth))
        .date(date);

      return {
        today: this.isToday(newDate),
        date: newDate,
        notThisMonth: !this.nextOrPrevious(newDate, selectedMonth),
      };
    });
  }

  getFirstDayOfNextMonth(currentDate: moment.Moment): string {
    const lastOfMonth = this.getLastDayOfMonth(currentDate);
    return currentDate
      .clone()
      .endOf('month')
      .subtract(lastOfMonth, 'days')
      .add(8, 'days')
      .toISOString();
  }

  generateMonthRow(month: CalendarDay[]): CalendarDay[][] {
    const monthRow: CalendarDay[][] = [];
    let row: CalendarDay[] = [];
    month.forEach((day, index) => {
      if (index % 7 === 0) {
        row = [];
        monthRow.push(row);
      }
      row.push(day);
    });
    return monthRow;
  }

  // !
  calculateEventWidth(cellWidth: number, lower: string, upper: string): number {
    if (!lower || !upper || !cellWidth) return 0;
    const start = this.momentService.moment(lower).startOf('day');
    const end = this.momentService.moment(upper).endOf('day');
    const startWeek = start.clone().week();
    const endWeek = end.clone().week();
    if (start.date() === end.date()) return cellWidth - 8;
    if (startWeek === endWeek) {
      return (end.diff(start, 'days') + 1) * cellWidth - 8;
    } else {
      return (
        (start.clone().endOf('week').diff(start, 'days') + 1) * cellWidth - 8
      );
    }
  }

  // *** HELPER METHODS ***

  isShown(date: moment.Moment, event: FullSchedule): boolean {
    return this.momentService
      .moment(date)
      .isBetween(event.duration.lower, event.duration.upper, 'day', '[]');
  }
  isSameDay(
    myEvent: FullSchedule,
    day: moment.Moment,
    position: 'start' | 'end',
  ) {
    return (
      (position === 'start' &&
        this.momentService.moment(myEvent.duration.lower).date() ===
          day.date()) ||
      (position === 'end' &&
        this.momentService.moment(myEvent.duration.upper).date() === day.date())
    );
  }
  // *** HELPER METHODS END ***

  // *** GENERATE CALENDAR METHODS ***

  // !
  getFirstDayOfMonth(currentDate: moment.Moment): number {
    return currentDate.clone().startOf('month').day();
  }

  // !
  getLastDayOfMonth(currentDate: moment.Moment): number {
    return currentDate.clone().endOf('month').day();
  }

  // !
  getFirstDayOfGrid(currentDate: moment.Moment): moment.Moment {
    const firstOfMonth = this.getFirstDayOfMonth(currentDate);
    return currentDate.clone().startOf('month').subtract(firstOfMonth, 'days');
  }

  // !
  getLastDayOfGrid(currentDate: moment.Moment): moment.Moment {
    const lastOfMonth = this.getLastDayOfMonth(currentDate);
    return currentDate
      .clone()
      .endOf('month')
      .subtract(lastOfMonth, 'days')
      .add(7, 'days');
  }

  // !
  isToday(date: moment.Moment): boolean {
    return this.momentService
      .moment(date)
      .isSame(this.momentService.moment(), 'day');
  }

  // !
  nextOrPrevious(day: moment.Moment, currentDate: moment.Moment): boolean {
    return this.momentService
      .moment(day)
      .isBetween(
        currentDate.clone().startOf('month'),
        currentDate.clone().endOf('month'),
        'day',
        '[]',
      );
  }

  // *** GENERATE CALENDAR METHODS END ***

  // *** ORDER EVENTS METHODS ***
  // !
  getOrderedEvents(eventsList: FullSchedule[]): FullSchedule[] {
    if (!eventsList || eventsList.length === 0) {
      return [];
    }

    return [
      ...eventsList
        .sort((a, b) => {
          return this.momentService
            .moment(a.duration.lower)
            .diff(this.momentService.moment(b.duration.lower));
        })
        .map(({ order, ...ev }) => {
          return ev;
        }),
    ];
  }

  assignOrdersToEvents(events: FullSchedule[]): void {
    const currentDayOrders = new Set<number>();
    let currentOrder = -1;

    for (const ev of events) {
      if (ev.order) {
        currentDayOrders.add(ev.order);
      }
    }

    for (const ev of events) {
      if (!currentDayOrders.has(ev.order)) {
        currentOrder = this.getNextOrder(currentOrder, currentDayOrders);
        ev.order = currentOrder;
      }
    }
  }

  private getNextOrder(order: number, orders: Set<number>): number {
    if (orders.has(order + 1)) {
      return this.getNextOrder(order + 1, orders);
    } else {
      return order + 1;
    }
  }

  // *** ORDER EVENTS METHODS END ***

  // *** RESIZE EVENT METHODS ***

  getEventDuration(
    position: 'start' | 'end',
    day: moment.Moment,
    myEvent: FullSchedule,
  ): { lower: string; upper?: string } {
    let lower: string;
    let upper: string;

    if (position === 'start') {
      lower = day.clone().startOf('day').toISOString();
    } else {
      lower = this.momentService
        .moment(myEvent.duration.lower)
        .startOf('day')
        .toISOString();
    }

    if (position === 'end') {
      upper = day.clone().endOf('day').toISOString();
    } else {
      upper = this.momentService
        .moment(myEvent.duration.upper)
        .endOf('day')
        .toISOString();
    }
    if (myEvent.duration.continuous) {
      return { lower };
    } else {
      return { lower, upper };
    }
  }

  isEventsIntersecting(
    event: ScheduleInterface,
    eventsList: FullSchedule[],
    itemType: 'vehicle' | 'driver',
  ): boolean {
    const start = this.momentService.moment(event.duration.lower);
    const end = this.momentService.moment(event.duration.upper);

    return eventsList
      .filter((evt) =>
        itemType === 'vehicle'
          ? evt?.vehicle?.id === event?.vehicle && evt?.id !== event?.id
          : evt?.operator?.operator?.id === event?.operator &&
            evt?.id !== event?.id,
      )
      .some((ev) => {
        return (
          this.momentService
            .moment(ev.duration.lower)
            .isBetween(start, end, 'day', '[]') ||
          this.momentService
            .moment(ev.duration.upper)
            .isBetween(start, end, 'day', '[]')
        );
      });
  }

  isEventOutOfBounds(myEvent, day, position): boolean {
    const upperBound = this.momentService.moment(myEvent.duration.upper);
    const lowerBound = this.momentService.moment(myEvent.duration.lower);
    let isOutOfBounds = false;

    if (position === 'start') {
      isOutOfBounds = day.isAfter(upperBound, 'd');
    } else if (position === 'end') {
      isOutOfBounds = day.isBefore(lowerBound, 'd');
    }

    return isOutOfBounds;
  }
}
