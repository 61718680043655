import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FullSchedule } from 'src/app/core/models/operator-schedule.model';
import { DialogsService } from '@fc-core/services/dialogs.service';
import { ConfirmDialogData } from '@fc-shared/ui/confirm-dialog/confirm-dialog.data';

@Component({
  selector: 'fc-search-list',
  template: `
    <div class="events">
      <div
        class="event-container"
        *ngFor="let event of eventList; trackBy: trackByFn"
      >
        <div class="event">
          <div class="event-data">
            <div
              [matMenuTriggerFor]="eventMenu"
              #eventMenuTrigger="matMenuTrigger"
              class="event"
            >
              <div class="event-title">
                <ng-container
                  *ngTemplateOutlet="
                    itemTemplate;
                    context: { $implicit: event }
                  "
                ></ng-container>
              </div>
              <div class="event-date">
                <fc-icon icon="icon-date_range" [size]="16"></fc-icon>
                <div>
                  {{ event.duration.lower | momentFormat: 'MM/DD/YY' }}
                </div>
                <div>-</div>
                <div>
                  {{ event.duration.upper | momentFormat: 'MM/DD/YY' }}
                </div>
              </div>
            </div>
          </div>
          <mat-menu #eventMenu="matMenu">
            <button
              mat-menu-item
              (mousedown)="onMouseClicked(event, eventMenuTrigger)"
            >
              <fc-icon icon="icon-create"></fc-icon>
              Edit
            </button>
            <button
              mat-menu-item
              (mousedown)="removeEvent(event, eventMenuTrigger)"
            >
              <fc-icon icon="icon-delete_outline"></fc-icon>
              Delete
            </button>
          </mat-menu>
        </div>
      </div>
      <fc-empty-state
        *ngIf="eventList.length === 0"
        title="No results found"
        subtitle="Try different settings"
      ></fc-empty-state>
    </div>
  `,
  styleUrls: ['./search-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SearchListComponent {
  @Input() itemType: 'vehicle' | 'driver';
  @Input() eventsList: FullSchedule[];
  @Input() searchValue: string;
  @Input() searchKey = 'identifier';
  @Input() itemTemplate: TemplateRef<any>;

  @Output() eventMoved: EventEmitter<FullSchedule> = new EventEmitter();
  @Output() editEvent: EventEmitter<FullSchedule> = new EventEmitter();
  @Output() deleteEvent: EventEmitter<FullSchedule> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private dialogsService: DialogsService,
  ) {}

  get eventList(): FullSchedule[] {
    if (this.searchValue.trim().length === 0) return [];
    return this.eventsList.filter((event) => {
      if (this.itemType === 'vehicle') {
        return event.vehicle?.[this.searchKey]
          .toLowerCase()
          .includes(this.searchValue.toLowerCase());
      } else {
        return event.operator?.[this.searchKey]
          .toLowerCase()
          .includes(this.searchValue.toLowerCase());
      }
    });
  }

  trackByFn = (index) => index;

  removeEvent(event: FullSchedule, matMenuTrigger) {
    matMenuTrigger.closeMenu();

    const deleteAction = () =>
      this.deleteEvent.emit(event as unknown as FullSchedule);

    const confirmData: ConfirmDialogData = {
      title: `Deleting Schedule?`,
      message: 'Are you sure want to delete Schedule?',
      buttonText: 'Delete',
      action: deleteAction,
      buttonColor: 'error',
    };

    this.dialogsService.openConfirmDialog(confirmData);
  }

  onMouseClicked(event, matMenuTrigger) {
    matMenuTrigger.closeMenu();
    this.editEvent.emit(event);
  }
}
