<div class="calendar">
  <fc-controls
    [currentDate]="currentCalendarMonth"
    [itemType]="itemType"
    [openEditFunction]="openEditFunction"
    (dateSelected)="setCurrentMonth($event)"
    (nextDate)="nextDate()"
    (previousDate)="previousDate()"
    (searchChange)="search = $event"
  >
  </fc-controls>
  <div *ngIf="!search.length; else searchList">
    <fc-date-content
      [itemTemplate]="itemTemplate"
      [currentDate]="currentCalendarMonth"
      [eventsList]="eventList"
      [loading]="loading"
      [vehicleList]="vehicleList"
      [dayClickFunction]="dayClickFunction"
      [itemType]="itemType"
      (eventMoved)="moveEvent($event)"
      (editEvent)="editEvent.emit($event)"
      (deleteEvent)="deleteEvent.emit($event)"
    >
    </fc-date-content>
  </div>
  <ng-template #searchList>
    <fc-search-list
      [itemTemplate]="itemTemplate"
      [eventsList]="eventList"
      [searchValue]="search"
      (eventMoved)="moveEvent($event)"
      (editEvent)="editEvent.emit($event)"
      (deleteEvent)="deleteEvent.emit($event)"
      [itemType]="itemType"
      [searchKey]="itemType === 'vehicle' ? 'identifier' : 'fullName'"
    >
    </fc-search-list>
  </ng-template>
</div>
